import { lazy } from "react";
import {
  BackgroundContainer
} from "./styles";


const Container = lazy(() => import("../../common/Container"));
const ScrollToTop = lazy(() => import("../../common/ScrollToTop"));
const MyNFTBlock = lazy(() => import ("../../components/MyNFT/MyNFTBlock"));


const Home = () => {
  return (
    <>
    <BackgroundContainer>
        <div style={{
              
        }}>
          <Container>
            <MyNFTBlock/>
          </Container>
        </div>
        <ScrollToTop />
    </BackgroundContainer>
    </>
  );
};
export default Home;
